const API = {
        "SMITCH_WEARABLES": "https://app-server.dev-smitch-wearables.mysmitch.com",
        "SMITCH_SECURE": "https://app-server.dev-smitch-security.mysmitch.com",
        "SMITCH_HOME": "https://app-server.dev-smitch-home.mysmitch.com",
};

export {API}

const ADMIN_PANEL_BASE = "https://api.admin-panel.mysmitch.com/v1/"

export {ADMIN_PANEL_BASE}