import React, { useState, useEffect } from "react";
import "./App.css";
import { API, ADMIN_PANEL_BASE } from "./constant";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Button from '@material-ui/core/Button';



declare global {
  interface Window {
    hsConversationsSettings: any;
    HubSpotConversations: any;
    hsConversationsOnReady: any;
  }
}

let hsConversationsSettings = window.hsConversationsSettings;

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },



}));

const App = () => {
  const queryParams = new URLSearchParams(window.location.search);

  const [user, setUser] = useState({
    email: queryParams.get("email"),
    token: queryParams.get("token"),
    hubspot_vid: queryParams.get("hubspot_vid"),
    device_source: queryParams.get("device_source"),
  });
  const [data, setData] = useState({});
  const [show, setShow]=useState(false)

  useEffect(() => {
    getWishText();
    let temp = user;
    sendUser(temp);
  }, []);

  const getWishText = async () => {
    try {
      const result = await axios.get(ADMIN_PANEL_BASE + "chatsupportconfig/getActive", {})
      if (result.data && result.data.data) {
        setData(result.data.data)
        setShow(result.data.data.is_active)

      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const sendUser = async (user: any) => {
    const { email, hubspot_vid, device_source, token } = user;
    try {
      //@ts-ignore
      const result = await axios.post(API[device_source], {
        hubspot_vid,
        email,
      });


    } catch (error) {
      console.error(error);
    }

    window.hsConversationsSettings = {
      identificationEmail: email || "",
      identificationToken: token || "",
    };
    function onConversationsAPIReady() {
      console.log(`HubSpot Conversations API: ${window.HubSpotConversations}`);
    }

    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }
  };

  return (
    <React.Fragment>
      <div className="App" >
        <Container component="main" maxWidth="xs">
          <Grid >
            <div className="image">
              <img
                src={require("../src/Logo.png")}
                alt="logo"
                height="29px"
                width="158px"
              />
            </div>
            <br></br>
            <h3 style={{fontFamily:"Nunito"}}>
            Need assistance with your Smitch products?
            <br></br>
GET IN TOUCH WITH OUR EXPERTS.
            </h3>
            {/* <h3 className="headText">
              CAN'T FIND WHAT YOU NEED IN OUR
              <br></br>
              <a href="https://mysmitch.force.com/MySmitch/s/">  HELP DOCS?</a>
              <br></br>
              We've got your back!
              <br></br>
              GET IN TOUCH WITH OUR EXPERTS.</h3> */}
          </Grid>
          <Grid>
            {/* 
                                                                // @ts-ignore */}
            <h3 className="wish" style={{ color: "#8A2BE2" }} >{data.title} </h3>
            {/* 
                                                                // @ts-ignore */}
            <p className="wish">{data.description}</p>
              {/* 
                                                                // @ts-ignore */}
            {data.description?
            <p className="wish">Thank you, have a nice day</p>:null}

          </Grid>
          <Grid className="middle">

            <img
              src={require("../src/Creative.png")}
              alt="mail logo"
              width="263"
              height="255"

            />

          </Grid>

          {/* <Grid container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={3} >

              <img
                src={require("../src/Chat_Button.svg")}
                alt="mail logo"
                width="50"
                height="40"
              />
            </Grid>
            <Grid >
              <h2 className="textPos">Let's Talk</h2>
            </Grid>
          </Grid> */}
          {true?null:
          <Grid container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
          >
            <Grid item xs={3} >
              <Button href="tel:+911204032728">
                <img
                  src={require("../src/Call_button.svg")}
                  alt="mail logo"
                  width="50"
                  height="40"
                />
              </Button>
            </Grid>
            <Grid >
              <h4 className="textPos">Call Support  <br></br> </h4>
              +911204032728

            </Grid>
          </Grid>
          }
          <Grid
            container
            direction="row"
            alignItems="flex-start"

          >
            <Grid item xs={3} >
              <Button href="mailto:support@mysmitch.com">
                <img
                  src={require("../src/Email_Button.svg")}
                  alt="mail logo"
                  width="50"
                  height="40"
                />
              </Button>
            </Grid>
            <Grid item xs={4} className="textPos">
              <h4 className="textPos2">Email Support</h4>
              support@mysmitch.com
            </Grid>
          </Grid>
          {/* <Grid       container
            direction="row"
            alignItems="flex-start">
          <Button href="https://play.google.com/store/apps/details?id=com.mysmitch.ssv2" target="_blank">
                <img
                  src={require("../src/ItunesArtwork@2x.png")}
                  alt="app logo"
                  width="50"
                  height="50"
                />
              </Button>
          <Grid item  xs={8} md={8} >
            
              <h4 className="textPos3">&nbsp; &nbsp; Smitch Secure Android App</h4>
              <a  className="linkText" href="https://play.google.com/store/apps/details?id=com.mysmitch.ssv2" target="_blank">Click here to download the Smitch Secure app.
</a>
            </Grid>
          </Grid> */}
        </Container>
      </div>
    </React.Fragment>
  )
};

export default App;
